<template>
	<div class="alter">
		<frame :top1="top1" :top2="top2">
			商品批量修改
		</frame>
	</div>
</template>

<script>
	import frame from '../public/Frame.vue';
	export default {
		name: 'alter',
		data() {
			return {
				top1: '4-5',
				top2: ['4']
			}
		},
		components: {
			frame
		},
		created() {
			
		}
	}
</script>

<style scoped="scoped">
</style>
